<template>
  <v-app-bar id="app-bar" sticky app color="blue" class="text-center">
    <v-img src="@/assets/img/navbar.png" contain max-width="150"/>
    <v-toolbar-title class="white--text">
      <span class="headline">Te pone sobre ruedas</span>
    </v-toolbar-title>

    <v-spacer />
    <div class="mx-3" />
    <span class="headline white--text text-h6 mr-3">{{ date }}</span>
    <span class="headline white--text text-h6">{{ time }}</span>
    <v-btn class="ml-1 mt-1 mb-1" min-width="0" text to="/logout">
      <v-icon color="white">mdi-logout</v-icon>
    </v-btn>
  </v-app-bar>
</template>
<script >
export default {
  data() {
    return {
      time: "",
      date: "",
    };
  },
  mounted() {
    this.getDate();
    this.runClock();
  },
  methods: {
    getTime() {
      var currentTime = new Date();
      var hours = currentTime.getHours();
      var minutes = currentTime.getMinutes();
      var seconds = currentTime.getSeconds();
      var meridian = "AM";
      if (hours > 12) {
        meridian = "PM";
      }
      if (hours > 12) {
        hours = hours - 12;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      this.time = hours + ":" + minutes + ":" + seconds + " " + meridian;
    },
    runClock() {
      setInterval(this.getTime, 1000);
    },
    getDate() {
      var currentTime = new Date();
      var day = currentTime.getDate();
      var month = currentTime.getMonth() + 1;
      var year = currentTime.getFullYear();
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }

      this.date = day + "/" + month + "/" + year;
    },
  },
};
</script>